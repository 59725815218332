import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PATH_NAME } from "./pathNames";

const PublicRoute = ({ path, exact, Component, authed, ...rest }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        return (
          <Component {...props} {...rest} />
        );
      }}
    />
  );
};

export default PublicRoute;
