import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PATH_NAME } from "../../routes/pathNames";
import { whatsAppUrl } from "../../utils/Constants";
import { ChatAlt2Icon } from "@heroicons/react/solid";

export default function Layout(props) {
  const { i18n } = useTranslation();
  const location = useLocation();

  return (
    <div dir={i18n.dir()}>
      <div>
      
        {props.children}
        <a href={whatsAppUrl} className="float" target="_blank" rel="noreferrer">
          <ChatAlt2Icon className="p-3 text-green-700" />
        </a>
      </div>
    </div>
  );
}
