import React, { useEffect, useState } from "react";
import { getUser } from "../apis/Auth";
import app from "./config";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(false);
  const [podcastDetail, setPodcastDetail] = useState(null);

  useEffect(() => {
    console.log("Auth UseEffect")
    app.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then(async function (idToken) {
          localStorage.setItem("token", idToken);
          const res = await getUser();
          localStorage.setItem("user", `${res?.username}, ${res?.email}`);
          setUser({ ...user, username: res?.username, usermail: res?.email });
        });
      } else {
        setUser(false)
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ user, podcastDetail, setPodcastDetail }}>
      {children}
    </AuthContext.Provider>
  );
};
