import { axiosInstance } from "../index";
import {
  firebaseSignIn,
  firebaseResetPassword,
  firebaseSignOut,
} from "../firebase/authFunctions";

export const signUp = async (data) => {
  const response = await axiosInstance.post("v2/createUser", data);
  const responseData = response.data;
  if (responseData.result) {
    try {
      await firebaseSignIn(data.email, data.pass);
    } catch (error) {
      return {
        error,
      };
    }
  } else {
    return {
      error: responseData.errorMessage,
    };
  }
  return {
    error: null,
  };
};

export const signIn = async (data) => {
  try {
    const response = await firebaseSignIn(data.email, data.pass);
    return response;
  } catch (error) {
    return {
      error,
    };
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await firebaseResetPassword(data.email);
    return response;
  } catch (error) {
    return {
      error,
    };
  }
};

export const signOut = async () => {
  try {
    const response = await firebaseSignOut();
    return response;
  } catch (error) {
    return {
      error,
    };
  }
};

export const getUser = async () => {
  const response = await axiosInstance.get("getUser");
  return response.data;
};
