import Cookies from "universal-cookie";
import { getUser } from "../network/apis/Auth";

const cookies = new Cookies();

export const setCookie = (cname, cvalue, exdays) => {
  var date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  cookies.set(cname, cvalue, { path: "/", expires: date });
};

export const getCookie = (cname) => {
  return cookies.get(cname);
};

export const getUserFromCookies = () => {
  var user = getCookie("user");
  if (user !== "") {
    return user;
  } else {
    getUser();
  }
};
