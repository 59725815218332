import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PATH_NAME } from "./pathNames";

const PrivateRoute = ({ path, exact, Component, authed, ...rest }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        console.log(props.match)
        return authed || localStorage.getItem("user") ? (
          <Component {...props} {...props.match.params} {...rest} />
        ) : (
          <Redirect to={PATH_NAME.HOME} />
        );
      }}
    />
  );
};

export default PrivateRoute;
