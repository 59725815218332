import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AuthProvider } from "./network/firebase/context";
import "./i18n";
import Routes from "./routes/routes";
import "./app.scss"
const App = () => (
  <AuthProvider>
    <Router history={createBrowserHistory()}>
      <Routes />
    </Router>
  </AuthProvider>
);

export default App;
