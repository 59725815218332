import { setCookie } from "../../utils/Auth";
import firebase from "./config";

export const firebaseSignIn = async (email, password) => {
  const data = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  setCookie("user", data?.user);
  return data;
};

export const firebaseResetPassword = async (email) => {
  return await firebase.auth().sendPasswordResetEmail(email);
};

export const firebaseSignOut = async () => {
  return await firebase.auth().signOut();
};
