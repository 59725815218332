import { signOut } from "./apis/Auth";
import { PATH_NAME } from "../routes/pathNames";

export const requestHandler = (request) => {
  const token = localStorage.getItem("token");
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
  return request;
};

export const responseErrorHandler = (error) => {
  const status = error?.response?.status
  try {
    if (status === 401) {
      signOut().then((res) =>
        res?.error
          ? window.alert(res?.error)
          : (window.location.href = PATH_NAME.SIGN_IN)
      );
    }
    return Promise.reject(error);
  } catch (err) {
    return Promise.reject(err);
  }
};
